import React from 'react'
import aws from '../../images/integrate/aws.png'
import azure from '../../images/integrate/azure.png'
import onfido from '../../images/integrate/onfido.png'
import magento from '../../images/integrate/magento.png'
import trac from '../../images/integrate/trac.png'
import mailchimp from '../../images/integrate/mailchimp.png'
import docker from '../../images/integrate/docker.png'
import heroku from '../../images/integrate/heroku.png'
import kubernetes from '../../images/integrate/kubernetes.png'
import shopify from '../../images/integrate/shopify.png'
import scim from '../../images/integrate/scim.png'
import digitalocean from '../../images/integrate/digitalocean.png'
import graphql from '../../images/integrate/graphql.png'
import dialogflow from '../../images/integrate/dialogflow.png'
import skype from '../../images/integrate/skype.png'
import hootsuite from '../../images/integrate/hootsuite.png'
import connectwise from '../../images/integrate/connectwise.png'
import slack from '../../images/integrate/slack.png'
import sendgrid from '../../images/integrate/sendgrid.png'
import artemis from '../../images/integrate/artemis.png'
import okta from '../../images/integrate/okta.png'
import quorum from '../../images/integrate/quorum.png'
import pagerduty from '../../images/integrate/pagerduty.png'
import googlecloud from '../../images/integrate/googlecloud.png'

import ScrollAnimation from 'react-animate-on-scroll'

class Item extends React.Component {
  constructor(props){
    super(props);
  }

//{pp[teamMate.profilePicture]}
  render() {
    const tool_name = this.props.name;
    const tool_service = this.props.service;
    const tool_logo = this.props.logo;
    const delay = this.props.delay;

    const logo = {
      aws : aws,
      azure: azure,
      onfido : onfido,
      magento : magento,
      trac : trac,
      mailchimp : mailchimp,
      docker : docker,
      heroku : heroku,
      kubernetes : kubernetes,
      shopify : shopify,
      scim : scim,
      digitalocean : digitalocean,
      graphql : graphql,
      dialogflow : dialogflow,
      artemis: artemis,
      skype : skype,
      sendgrid: sendgrid,
      slack: slack,
      hootsuite : hootsuite,
      googlecloud: googlecloud,
      pagerduty: pagerduty,
      connectwise: connectwise,
      okta: okta,
      quorum: quorum
    };
    return(      
      <div className="tool-item col-md-3 no-margin ">
        <ScrollAnimation animateIn="subtleFadeInRight" duration="1" delay={delay} animateOnce="true">
          <div className="row">
            <div className="tool-logo col-md-3">
              <img src={logo[tool_logo]} alt={tool_name}/>
            </div>
            <div className="tool-desc col-md-6">
              <div className="tool-name row">
                {tool_name}
              </div>
              <div className="service row">
                {tool_service}
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>      
    )
  }
}

export default Item;
