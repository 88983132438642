import React from 'react'
import FullPageBlock from '../full-page-block'
import PropTypes from 'prop-types';
import Item from './item'

const List = ({tools}) => (
  <FullPageBlock style={{ bgColor : "#381D74", height: '85%'}}>
    <div className="tools row">
        {          
          tools.map(
            ({name, service, logo}, index) =>              
                <Item name={name} service={service} logo={logo} delay={index * 50} />
          )
        }
        <div className="boast col-md-10 ">
        </div>
    </div>
  </FullPageBlock>
);

List.propTypes = {
  tools: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      service: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
  })),
};


export default List;
