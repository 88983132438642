const tools = [
  {
    name: "AWS",
    service: "Cloud Computing",
    logo: "aws"
  },
  {
    name: "Artemis",
    service: "Project Management",
    logo: "artemis"
  },
  {
    name: "Azure",
    service: "Cloud Computing",
    logo: "azure"
  },
  {
    name: "ConnectWise",
    service: "IT",
    logo: "connectwise"
  },
  {
    name: "DialogFlow",
    service: "Developer Tools",
    logo: "dialogflow"
  },
  {
    name: "DigitalOcean",
    service: "Cloud Computing",
    logo: "digitalocean"
  },
  {
    name: "Docker",
    service: "Developer Tools",
    logo: "docker"
  },
  {
    name: "Google Cloud",
    service: "Cloud Computing",
    logo: "googlecloud"
  },
  {
    name: "GraphQL",
    service: "Developer Tools",
    logo: "graphql"
  },
  {
    name: "Heroku",
    service: "Cloud Computing",
    logo: "heroku"
  },
  {
    name: "HootSuite",
    service: "Marketing",
    logo: "hootsuite"
  },
  {
    name: "Kubernetes",
    service: "Developer Tools",
    logo: "kubernetes"
  },

  {
    name: "Magento",
    service: "eCommerce",
    logo: "magento"
  },
  {
    name: "Mailchimp",
    service: "Marketing",
    logo: "mailchimp"
  },
  {
    name: "Okta",
    service: "Cloud Computing",
    logo: "okta"
  },
  {
    name: "Onfido",
    service: "Developer Tools",
    logo: "onfido"
  },
  {
    name: "Pagerduty",
    service: "Cloud Computing",
    logo: "pagerduty"
  },
  {
    name: "Quorum",
    service: "Developer Tools",
    logo: "quorum"
  },
  {
    name: "SCIM",
    service: "Developer Tools",
    logo: "scim"
  },
  {
    name: "Sendgrid",
    service: "Marketing",
    logo: "sendgrid"
  },
  {
    name: "Shopify",
    service: "eCommerce",
    logo: "shopify"
  },
  {
    name: "Skype",
    service: "Communication",
    logo: "skype"
  },
  {
    name: "Slack",
    service: "Communication",
    logo: "slack"
  },
  {
    name: "Trac",
    service: "Cloud Computing",
    logo: "trac"
  }
]

export default tools
