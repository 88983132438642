import React from 'react'
import Layout from '../components/layout'
import Meet from '../components/integrations/meet'
import List from '../components/integrations/list'
import tools from '../components/integrations/tools.config.js'

const IntegrationsPage = () => (
  <Layout>
    <Meet />
    <List tools={tools}/>
  </Layout>
)

export default IntegrationsPage
