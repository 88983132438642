import React from 'react'
import meetImage from '../../images/meetImage.png'
import ScrollAnimation from 'react-animate-on-scroll'

const Meet = () => (
  <div className="meet-bg" style={{backgroundImage:'url(' + {meetImage }+')'}}>
    <div className="container">
      <ScrollAnimation animateIn="subtleFadeInUp" duration="2" animateOnce="true">
        <div className="meet-content row align-items-center">
          <div className="meet-top ">Integrations we have worked with</div>
          <div className="meet-desc ">These are all the integrations we have worked throughout our <br/>
          journey which helped speed our processes.</div>
        </div>
      </ScrollAnimation>
    </div>
  </div>
)

export default Meet;
